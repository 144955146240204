<template>
  <button
    :class="{
      'bg-blue-600 text-white': isHighlighted,
      'text-gray-700': !isHighlighted,
    }"
    class="flex h-full w-full items-center justify-between px-4 py-2 text-left text-sm leading-5 focus:outline-none"
  >
    <div class="flex items-center space-x-3">
      <img
        v-if="option.photo"
        :src="option.photo"
        class="h-6 w-6 shrink-0 rounded-full"
      />

      <span
        class="truncate"
        :class="{
          'font-semibold': isHighlighted,
          'font-normal': !isHighlighted,
        }"
      >
        {{ option.label }}
        <div
          v-if="option.sublabel"
          class="truncate text-xs"
          :class="{
            'text-blue-200': isHighlighted,
            'text-gray-500': !isHighlighted,
          }"
        >
          {{ option.sublabel }}
        </div>
      </span>
    </div>

    <span
      :class="{ 'text-white': isHighlighted, 'text-blue-600': !isHighlighted }"
      v-if="isSelected"
    >
      <icon name="check" :solid="true" class="h-5 w-5" aria-hidden="true" />
    </span>
  </button>
</template>

<script>
export default {
  props: {
    isHighlighted: {
      type: Boolean,
      default: false,
    },
    option: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
