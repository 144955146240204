<template>
  <div class="flex items-center" role="figure" :aria-label="number">
    <number-counter :background-color="counterBackgroundColor" v-if="isCurrency"
      >£</number-counter
    >
    <template v-for="character in characters" :key="key(character)">
      <div class="mx-1" v-if="character == ','">,</div>
      <number-counter :background-color="counterBackgroundColor" v-else>{{
        character
      }}</number-counter>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: Number,
      required: true,
    },
    isCurrency: {
      type: Boolean,
      default: false,
    },
    counterBackgroundColor: {
      type: String,
      default: "bg-pale-grey-100",
    },
    incrementAmount: {
      type: Number,
    },
  },
  data() {
    return {
      currentNumber: 0,
    }
  },
  computed: {
    characters() {
      return this.currentNumber.toLocaleString().split("")
    },
  },
  methods: {
    key(character) {
      return character + Math.random()
    },
    tickUptoCurrentNumber() {
      if (this.incrementAmount) {
        this.interval = setInterval(() => {
          if (this.currentNumber < this.number) {
            this.currentNumber += Math.min(
              this.incrementAmount,
              this.number - this.currentNumber
            )
          } else {
            this.interval = clearInterval(this.interval)
          }
        }, 10)
      } else {
        this.currentNumber = this.number
      }
    },
  },
  watch: {
    number(newNumber, oldNumber) {
      // If the number prop changes, reset the current number to 0 and start ticking up again
      if (newNumber !== oldNumber) {
        this.currentNumber = 0
        if (this.interval) {
          this.interval = clearInterval(this.interval)
        }
      }

      // Don't try to start ticking it up if it is already ticking.
      // It will go to the number prop, because of he check in tickUptoCurrentNumber
      if (!this.interval) {
        this.tickUptoCurrentNumber()
      }
    },
  },
  created() {
    this.tickUptoCurrentNumber()
  },
}
</script>
