<template>
  <div class="flex h-14 w-28 items-center -space-x-2 overflow-hidden">
    <img
      v-for="person in people"
      :key="person.id"
      class="z-0 h-9 w-9 rounded-full ring-2 ring-white hover:z-10 hover:h-12 hover:w-12"
      :class="imageClasses(person)"
      :src="person.tiny_avatar_url"
    />
  </div>
</template>

<script>
export default {
  props: {
    people: Array,
  },
  data() {
    return {
      defaultImageClasses: "bg-yellow-100",
    }
  },
  methods: {
    imageClasses(person) {
      return person.image_classes || this.defaultImageClasses
    },
  },
}
</script>
