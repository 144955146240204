let scrollTop = 0
let shouldPreserveScroll = 0

const shouldPreserveScrollFn = (event) => {
  if (event.target.hasAttribute("data-preserve-scroll")) {
    shouldPreserveScroll = true
  } else {
    shouldPreserveScroll = false
  }
}

document.addEventListener("turbo:submit-start", shouldPreserveScrollFn)
document.addEventListener("turbo:click", shouldPreserveScrollFn)

document.addEventListener("turbo:visit", function (event) {
  if (shouldPreserveScroll) {
    scrollTop = document.documentElement.scrollTop
  } else {
    scrollTop = 0
  }
})

addEventListener("turbo:visit", () => {
  if (shouldPreserveScroll) {
    Turbo.navigator.currentVisit.scrolled = true
    document.documentElement.scrollTop = scrollTop
  }
})
