<template>
  <input
    type="range"
    class="range-with-number-thumb cursor-pointer appearance-none border-transparent bg-transparent"
    @input="onInput"
    ref="range"
  />
</template>
<script>
export default {
  data() {
    return {
      value: null,
    }
  },
  emits: ["update:modelValue", "input"],
  methods: {
    onInput(event) {
      this.$emit("input", event)
      this.$emit("update:modelValue", +event.target.value)
      this.value = event.target.value
    },
    updateThumbPositionCssVariable() {
      const { min, max } = this.$refs.range
      const range = max - min
      const valueAboveMin = this.value - min
      const percentage = valueAboveMin / range
      this.$refs.range.style.setProperty("--thumb-position", percentage)
    },
    updateValueCssVariable() {
      this.$refs.range.style.setProperty("--value", `"${this.value}"`)
    },
  },
  mounted() {
    this.value = this.$refs.range.value
  },
  watch: {
    value() {
      this.updateThumbPositionCssVariable()
      this.updateValueCssVariable()
    },
  },
}
</script>
