// We need to capture certain Vue errors & warnings and overwrite the page with a notification here,
// otherwise they can be missed in development mode and break production.
export const warnHandler = (message, instance, trace) => {
  handle("Warning", message)
}

function handle(type, message) {
  console.log(`Vue ${type}: ${message}`)

  if (shouldCrash(message)) {
    document.getElementById(
      "app"
    ).outerHTML = `<b>${type}: See full Vue ${type} in console.</b><p>${message}</p>`
  }
}

function shouldCrash(message) {
  return (
    message.startsWith(
      "Template compilation error: v-bind is missing expression."
    ) || message.endsWith("is not defined on instance.")
  )
}
