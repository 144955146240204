export const chartColors = [
  "#0aadff",
  "#41ac7d",
  "#ffae9c",
  "#f8d557",
  "#081f94",
  "#c72a7b",
  "#8395f8",
  "#5cc9e6",
  "#55bbf8",
]
