<template>
  <span v-if="time" :datetime="time" :title="time"></span>
</template>

<script>
import { render as timeAgoAsWords } from "timeago.js"

export default {
  props: {
    time: String,
    daysOnly: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.daysOnly && this.timeIsToday()) {
      this.$el.innerHTML = "Today"
    } else {
      timeAgoAsWords(this.$el)
    }
  },
  methods: {
    timeIsToday() {
      const now = new Date()
      const time = new Date(this.time)
      return now.toDateString() === time.toDateString()
    },
  },
}
</script>
