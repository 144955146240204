<template>
  <div style="margin: 0 3px" class="number-counter">
    <div
      class="flex h-full items-center justify-center rounded-t bg-pale-grey-100 font-bold"
      :class="backgroundColor"
    >
      <slot></slot>
    </div>
    <div class="h-[5%] bg-yellow-100 sm:h-[8%]"></div>
  </div>
</template>

<script>
export default {
  props: {
    backgroundColor: {
      type: String,
      default: "bg-pale-grey-100",
    },
  },
}
</script>

<style>
.number-counter {
  width: 1.13em;
  height: 1.7em;

  @screen sm {
    width: 1.28em;
    height: 1.8em;
  }
}
</style>
