export default {
  methods: {
    formatCurrency(value, decimalPlaces = 0) {
      if (parseInt(value) >= 1000) {
        return (
          "£" +
          value.toFixed(decimalPlaces).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        )
      } else {
        return "£" + value.toFixed(decimalPlaces)
      }
    },
  },
}
