<template>
  <div>
    <div class="relative">
      <span
        v-if="counterValue"
        class="-left-1 flex h-full w-full items-center justify-center rounded-full bg-pink-100 text-2xl font-bold text-white"
        style="
          position: absolute;
          transform: scale(0.5, 0.5) translate(-56%, -56%);
          bottom: 0;
        "
      >
        {{ counterValue }}
      </span>
      <component :is="icon" :class="iconClasses" />
      <component
        :is="badgeIcon"
        v-if="badgeName"
        ref="badge"
        style="
          position: absolute;
          transform: scale(0.6, 0.6) translate(51%, 51%);
          bottom: 0;
        "
      />
    </div>
  </div>
</template>

<script>
import * as solid from "@heroicons/vue/20/solid"
import * as outline from "@heroicons/vue/24/outline"
import * as micro from "@heroicons/vue/16/solid"

export default {
  props: {
    name: String,
    solid: {
      type: Boolean,
      default: false,
    },
    micro: {
      type: Boolean,
      default: false,
    },
    badgeName: {
      type: String,
      default: null,
    },
    counterValue: {
      type: Number,
      default: null,
    },
    iconClasses: {
      type: String,
      default: null,
    },
  },
  created() {
    if (!this.icon) {
      throw new Error(`No icon found for specified name of ${this.name}`)
    } else if (this.badgeName && !this.badgeIcon) {
      throw new Error(
        `No icon found for specified badge-name of ${this.badgeName}`
      )
    }
  },
  mounted() {
    if (this.badgeName) {
      const bg = this.badgeBackground()
      this.$refs.badge.setAttribute("fill", bg)
    }
  },
  computed: {
    icons() {
      return { solid, outline, micro }
    },
    icon() {
      if (this.micro) {
        return this.icons["micro"][this.constantizedName]
      }
      return this.icons[this.solid ? "solid" : "outline"][this.constantizedName]
    },
    // Icons are always outlines with a solid fill because solid icon on solid icon looks ugly
    badgeIcon() {
      return this.icons["outline"][this.constantizedBadgeName]
    },
    constantizedName() {
      return this.constantize(this.name)
    },
    constantizedBadgeName() {
      return this.constantize(this.badgeName)
    },
  },
  methods: {
    capitalize(word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    },
    constantize(name) {
      return (
        name
          .split("-")
          .map((word) => this.capitalize(word))
          .join("") + "Icon"
      )
    },
    // If the icon is an outline, we need to use the fill colour of the nearest
    // background so that it won't 'merge' with the main icon
    badgeBackground() {
      const transparentColorRegex = /rgba\(.*, 0\)/
      let parentNode = this.$refs.badge
      let color = "none"
      while (parentNode.parentNode && color === "none") {
        // It's probably a <!-- comment -->
        if (!parentNode.style) {
          parentNode = parentNode.parentNode
          continue
        }
        let backgroundColor =
          window.getComputedStyle(parentNode).backgroundColor
        if (!transparentColorRegex.test(backgroundColor)) {
          color = backgroundColor
        } else {
          parentNode = parentNode.parentNode
        }
      }
      return color
    },
  },
}
</script>
