export default {
  methods: {
    logEvent(eventName, eventLabel) {
      // Custom Event defined in Google Tag Manager
      // event-label is a custom variable defined in GTM
      this.initDataLayer()

      dataLayer.push({ event: eventName, "event-label": eventLabel })
    },
    logSignUp(userId, source) {
      this.initDataLayer()

      dataLayer.push({ event: "new-user", userid: userId, source: source })

      if (typeof fbq !== "undefined") {
        fbq("track", "CompleteRegistration")
      }
    },
    maybeLogMonthlyDonation() {
      if (this.shouldLogMonthlyDonation()) {
        this.logConversion("new-monthly-donation")
      }
    },
    maybeLogOneOffDonation() {
      if (this.shouldLogOneOffDonation()) {
        this.logConversion("new-one-off-donation")
      }
    },
    maybeLogGiftDonation() {
      if (this.shouldLogGiftDonation()) {
        this.logConversion("new-gift-donation")
      }
    },
    logConversion(eventName) {
      this.initDataLayer()

      const opts = {
        event: eventName,
        "transaction-id": gon.ecommerce.transaction_id,
        "transaction-value": gon.ecommerce.transaction_revenue,
      }

      dataLayer.push(opts)
    },
    initDataLayer() {
      window.dataLayer = window.dataLayer || []
    },
    shouldLogMonthlyDonation() {
      if (!gon.ecommerce) return false

      return gon.ecommerce.transaction_category == "Subscription"
    },
    shouldLogOneOffDonation() {
      if (!gon.ecommerce) return false

      const type = gon.ecommerce.transaction_category

      return type.indexOf("Payment") >= 0 && type.indexOf("Gift") < 0
    },
    shouldLogGiftDonation() {
      if (!gon.ecommerce) return false

      return gon.ecommerce.transaction_category.indexOf("Gift") >= 0
    },
  },
}
