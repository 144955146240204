<template>
  <div class="flex w-42 flex-col items-center justify-center text-center">
    <div
      class="relative mt-5 h-32 w-32 rounded-full border-6"
      :class="{ [`${color}-milestone-badge`]: true }"
    >
      <div
        class="absolute left-0 top-0 flex h-full w-full flex-wrap content-center justify-center text-5xl"
      >
        <slot name="emoji"></slot>
      </div>
      <div class="absolute -bottom-2 left-0 flex h-5 w-full justify-center">
        <div
          class="milestone-badge-label flex items-center rounded-full p-3 font-bold"
        >
          <slot name="milestone"></slot>
        </div>
      </div>
    </div>
    <div class="mt-4 font-bold"><slot name="description"></slot></div>
  </div>
</template>
<script>
export default {
  props: {
    color: { type: String },
  },
}
</script>
